import Button from '@swe/shared/ui-kit/components/button';

import { ApplicationPage } from '@swe/shop-ui/app/types';

const DebugPreloadError: ApplicationPage = () => {
  return (
    <div>
      <Button
        ariaLabel="click to error"
        onClick={() => {
          throw new Error('My tiny click handler error');
        }}
      >
        Click me to test cb error
      </Button>
    </div>
  );
};

DebugPreloadError.preload = () => {
  throw new Error('My tiny page preload error');
};

export default DebugPreloadError;
